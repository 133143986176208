







































import CategoryTreeviewComponent from "@/components/CategoryTreeviewComponent.vue"
import ProductListComponent from "@/components/ProductListComponent.vue"
import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI"
import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator"
import ProductService from "@/services/ProductService"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import Rules from "@/services/tool/Rules"
import Handler from "@/handlers/Handler"
import Product from "@/models/Product"
import SessionModule from "@/store/SessionModule";
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule";
import ProductDialog from "@/components/dialog/ProductDialog.vue";

@Component( { components: { ProductListComponent, CategoryTreeviewComponent, ProductDialog } } )
export default class OrderAddProductsTab extends Vue {
  @Ref() readonly form!: HTMLFormElement
  @Prop(Boolean) readonly pvp!: Boolean
  categoryTreeviewModule = getModule(CategoryTreeviewModule)

  productDialog: boolean = false
  product: Product = new Product()

  get pages() { return Math.ceil(this.products.totalItems!! / this.size) }


  get rules() { return Rules }
  get productCategories() { return this.categoryTreeviewModule.productCategories }
  get selectedCategories() { return this.categoryTreeviewModule.selectedCategories }
  set selectedCategories(categoryIds: number[]) { this.categoryTreeviewModule.setSelectedCategories(categoryIds) }

  lang: any = getModule(LangModule).lang
  sessionModule: SessionModule = getModule(SessionModule)
  isDistributor = this.sessionModule.session.authorities.map(e => e.title == "distributor")[0]
  page: number = 1
  size: number = 10
  search: string = ""

  products: MultipleItem<Product> = { items: [], totalItems: 0 }
  loading: boolean = false

  async created() {
    await this.refresh()
  }

  async refresh() {
    try {
      await Handler.getItems(this, this.products, () => ProductService.getProducts2(
          this.page - 1, this.size, this.search, this.selectedCategories, null, null, null
      ))
    } catch (e) {
      console.log(e)
    }
  }

  openProductDialog(product: Product) {
    this.product = product
    this.productDialog = true
  }

  addProduct(product: Product) {
    this.$emit("addOrderDetail", product)
  }

  @Watch('page')
  onPageChanged() {
    this.refresh()
  }

  @Watch('selectedCategories')
  onSelectedCategoriesChanged() {
    this.refresh()
  }

}
